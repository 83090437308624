<template>
  <div
    class="relative flex flex-col w-full h-full breakdown-component rounded"
    :class="{
      'bg-gray-800 text-gray-400': isOpen,
      'hover:bg-gray-300': !isOpen,
    }"
  >
    <div
      data-testId="breakdown-button"
      class="flex flex-row items-center justify-between h-full px-4 border-b-1"
      :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
      @click="openOrClose()"
    >
      <!-- slot name=label -->
      <label class="text-xs capitalize" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'">
        <span class="mr-1 font-bold"> Breakdown: </span>
        <span class="text-orange-500 font-bold">
          {{ optionSelected || label }}
        </span>
      </label>

      <span class="transition-100" :class="!isOpen ? 'rotate-180' : ''">
        <up-svg class="w-2 h-2" :class="disabled ? 'text-gray-400' : 'text-gray-500'" />
      </span>
    </div>

    <dismissible-background v-if="isOpen" @close="openOrClose()" />
    <transition name="fade" mode="out-in">
      <div
        v-show="isOpen"
        class="items-center bg-gray-800 text-gray-400 shadow-lg dropdown mt-3 rounded"
        :class="[horizontalGrow ? horizontalGroupByClasses : ' w-full']"
      >
        <div :class="[horizontalGrow ? 'flex' : '']">
          <div
            data-testId="breakdown-options"
            class="relative flex px-1 py-1"
            :class="[horizontalGrow ? 'w-48' : 'items']"
          >
            <div class="flex flex-col">
              <div class="w-full px-3 text-sm border-gray-200">
                <div v-if="!!realItemsList.name" class="pt-3 pb-2 text-left">
                  <span class="w-full font-bold">{{ realItemsList.name }}</span>
                </div>
                <div class="pb-1 pl-2">
                  <div class="flex flex-col">
                    <div
                      v-for="(o, oIndex) in realItemsList.items"
                      :key="`keyItem-${oIndex}`"
                      class="flex flex-row py-1 radio-item"
                      :class="{ disabled: disabled }"
                    >
                      <label :for="`item-${oIndex}`">
                        <input
                          :id="`item-${oIndex}`"
                          v-model.lazy="selectItem"
                          :disabled="disabled"
                          type="radio"
                          :name="o.name"
                          :value="oIndex"
                        />
                        <span>{{ o.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div
            v-show="showSecondary"
            v-if="!items.simple"
            class="relative flex px-1 py-1"
            :class="[horizontalGrow ? 'w-48 border-l' : 'items']"
          >
            <div class="flex flex-col">
              <div class="w-full px-3 text-sm border-gray-200">
                <div class="pt-3 pb-2 text-left">
                  <span class="w-full font-bold">{{ realSubItemsList.name }}</span>
                </div>
                <div class="pb-1 pl-2">
                  <div class="flex flex-col">
                    <div
                      v-for="(so, soIndex) in realSubItemsList.items"
                      :key="`keySubItem-${soIndex}`"
                      class="flex flex-row py-1 radio-item"
                      :class="{ disabled: disabled }"
                    >
                      <label :for="`subItems-${soIndex}`">
                        <input
                          :id="`subItems-${soIndex}`"
                          v-model.lazy="selectSubItem"
                          :disabled="disabled"
                          type="radio"
                          :name="so.name"
                          :value="soIndex"
                          @click="clickSubItem"
                        />
                        <span>{{ so.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="disabledSubItemsList || disabled" class="disabled-section-group" />
          </div>
        </div>
        <div class="flex items-center p-2 bg-gray-300" :class="[horizontalGrow ? 'w-full' : '']">
          <sun-button
            :disabled="disabled"
            color="orange"
            text-color="white"
            class="mr-2 text-xs rounded-full hover:bg-orange-100 custom-p-1"
            @click="apply"
          >
            <span>Apply</span>
          </sun-button>
          <sun-button
            :disabled="disabled"
            color="gray"
            text-color="white"
            class="text-xs rounded-full hover:bg-gray-100 custom-p-1"
            @click="reset"
          >
            <span>Reset</span>
          </sun-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * @typedef Breakdown
 * @property {string} name
 * @property {undefined|Breakdown[]} items
 */

import DismissibleBackground from '@/components/atoms/DismissibleBackground';
import { isValidBreakdown } from '@/utils/validation/isValidBreakdown';

export default {
  name: 'Breakdown',
  components: {
    DismissibleBackground,
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
  },
  props: {
    label: { type: String, default: 'Select any item' },
    /** @type {{new () : Breakdown }} */
    items: {
      type: Object,
      validator: isValidBreakdown,
      default: () => {},
    },
    disabled: { type: Boolean, default: false },
    /** @type {{new () : { index: number, subIndex: number }}} */
    value: { type: Object, default: () => ({}) },
    hideSecondary: { type: Boolean, default: () => false },
    horizontalGrow: { type: Boolean, default: () => false },
  },
  data: () => ({
    isOpen: false,
    /** @type number */
    selectItem: null,
    /** @type number */
    selectSubItem: null,
    realSubItemsList: [],
    disabledSubItemsList: true,
    optionSelected: '',
  }),
  computed: {
    /** @returns {Breakdown} */
    realItemsList() {
      return this.items;
    },
    showSecondary() {
      return !this.hideSecondary || !this.disabledSubItemsList;
    },
    horizontalGroupByClasses() {
      if (!this.showSecondary) return 'w-full';
      return 'flex flex-col';
    },
  },
  watch: {
    selectItem(val) {
      const subItems = this.realItemsList.items?.[val]?.items;
      this.disabledSubItemsList = !subItems;
      if (subItems) {
        this.realSubItemsList = subItems;
      }
      this.selectSubItem = null;
    },
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectItem = value.index ?? null;
        this.selectSubItem = value.subIndex ?? null;
        this.getOptionSelectedLabel(value);
      },
    },
    disabled(val) {
      if (val) {
        this.isOpen = false;
      }
    },
  },
  created() {
    const item = this.realItemsList.items.find(item => !!item.items?.items);
    this.realSubItemsList = item?.items || [];
  },
  methods: {
    openOrClose(force) {
      if (!this.isOpen) {
        this.selectItem = this.value.index ?? null;
        this.selectSubItem = this.value.subIndex ?? null;
      }
      if (this.disabled) return;
      this.isOpen = force ?? !this.isOpen;
    },
    apply() {
      if (this.disabled) return;
      const value = { index: this.selectItem, subIndex: this.selectSubItem };
      this.$emit('input', value);
      this.$emit('change', value);
      this.$emit('update:value', value);
      this.getOptionSelectedLabel(value);
      this.isOpen = false;
    },
    reset() {
      if (this.disabled) return;
      this.$emit('reset');
      this.isOpen = false;
    },
    /** @returns {string} */
    getOptionSelectedLabel({ index, subIndex }) {
      this.optionSelected = '';

      /** @returns {Breakdown} */
      const subItems = this.realItemsList.items?.[index]?.items;
      if (subItems && typeof subIndex === 'number') {
        const { name, items } = subItems;
        if (items[subIndex]?.name) {
          this.optionSelected = `${name || ''} ${items[subIndex].name}`;
        }
      } else if (this.realItemsList && typeof index === 'number') {
        const { name, items } = this.realItemsList;
        const item = items?.[index];
        if (item) {
          this.optionSelected = `${name || ''} ${item?.name || ''}`;
        }
      }
    },
    clickSubItem(event) {
      if (parseInt(event.target.value) === this.selectSubItem) {
        this.selectSubItem = null;
      }
    },
  },
};
</script>

<style lang="sass">
.group-disabled
  pointer-events: none

.disabled-section-group
  content: ''
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgb(225 231 239 / 67%)

.dropdown
  position: absolute
  right: auto
  left: 0
  top: 30px
  z-index: 1000

.items
  max-height: 260px
  overflow-x: auto
  scrollbar-color: #e2e8f0 #2d3748

.arrow-select
  -webkit-transition: -webkit-transform .2s ease
  transition: -webkit-transform .2s ease
  transition: transform .2s ease
  transition: transform .2s ease, -webkit-transform .2s ease

  &:after
    position: relative
    left: 0
    top: 10px
    color: #999
    border-color: #999 transparent transparent
    border-style: solid
    border-width: 5px 5px 0
    content: ""

.radio-item
  position: relative

  label > input[type="radio"]
    display: none

    + *::before
      content: ""
      display: inline-block
      vertical-align: bottom
      width: 1rem
      height: 1rem
      margin-right: 0.5rem
      border-radius: 50%
      border-style: solid
      border-width: 0.1rem
      border-color: #ff9c4b

    &:checked + span
      --text-opacity: 1

    &:checked + *
      color: #ff9c4b

      &::before
        background: radial-gradient(#ff9c4b 0%, #ff9c4b 40%, transparent 50%, transparent)
        border-color: #ff9c4b

    + span
      line-height: 13px

  label > input[type="radio"] + *
    display: inline-block
    cursor: pointer
</style>
