var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "relative flex flex-col w-full h-full breakdown-component rounded",
      class: {
        "bg-gray-800 text-gray-400": _vm.isOpen,
        "hover:bg-gray-300": !_vm.isOpen,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-row items-center justify-between h-full px-4 border-b-1",
          class: _vm.disabled ? "cursor-not-allowed" : "cursor-pointer",
          attrs: { "data-testId": "breakdown-button" },
          on: {
            click: function ($event) {
              return _vm.openOrClose()
            },
          },
        },
        [
          _c(
            "label",
            {
              staticClass: "text-xs capitalize",
              class: _vm.disabled ? "cursor-not-allowed" : "cursor-pointer",
            },
            [
              _c("span", { staticClass: "mr-1 font-bold" }, [
                _vm._v(" Breakdown: "),
              ]),
              _c("span", { staticClass: "text-orange-500 font-bold" }, [
                _vm._v(" " + _vm._s(_vm.optionSelected || _vm.label) + " "),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "transition-100",
              class: !_vm.isOpen ? "rotate-180" : "",
            },
            [
              _c("up-svg", {
                staticClass: "w-2 h-2",
                class: _vm.disabled ? "text-gray-400" : "text-gray-500",
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isOpen
        ? _c("dismissible-background", {
            on: {
              close: function ($event) {
                return _vm.openOrClose()
              },
            },
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen",
              },
            ],
            staticClass:
              "items-center bg-gray-800 text-gray-400 shadow-lg dropdown mt-3 rounded",
            class: [
              _vm.horizontalGrow ? _vm.horizontalGroupByClasses : " w-full",
            ],
          },
          [
            _c("div", { class: [_vm.horizontalGrow ? "flex" : ""] }, [
              _c(
                "div",
                {
                  staticClass: "relative flex px-1 py-1",
                  class: [_vm.horizontalGrow ? "w-48" : "items"],
                  attrs: { "data-testId": "breakdown-options" },
                },
                [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "w-full px-3 text-sm border-gray-200" },
                      [
                        !!_vm.realItemsList.name
                          ? _c("div", { staticClass: "pt-3 pb-2 text-left" }, [
                              _c("span", { staticClass: "w-full font-bold" }, [
                                _vm._v(_vm._s(_vm.realItemsList.name)),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "pb-1 pl-2" }, [
                          _c(
                            "div",
                            { staticClass: "flex flex-col" },
                            _vm._l(
                              _vm.realItemsList.items,
                              function (o, oIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: `keyItem-${oIndex}`,
                                    staticClass:
                                      "flex flex-row py-1 radio-item",
                                    class: { disabled: _vm.disabled },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: `item-${oIndex}` } },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.lazy",
                                              value: _vm.selectItem,
                                              expression: "selectItem",
                                              modifiers: { lazy: true },
                                            },
                                          ],
                                          attrs: {
                                            id: `item-${oIndex}`,
                                            disabled: _vm.disabled,
                                            type: "radio",
                                            name: o.name,
                                          },
                                          domProps: {
                                            value: oIndex,
                                            checked: _vm._q(
                                              _vm.selectItem,
                                              oIndex
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.selectItem = oIndex
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v(_vm._s(o.name))]),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("hr"),
              !_vm.items.simple
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSecondary,
                          expression: "showSecondary",
                        },
                      ],
                      staticClass: "relative flex px-1 py-1",
                      class: [_vm.horizontalGrow ? "w-48 border-l" : "items"],
                    },
                    [
                      _c("div", { staticClass: "flex flex-col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "w-full px-3 text-sm border-gray-200",
                          },
                          [
                            _c("div", { staticClass: "pt-3 pb-2 text-left" }, [
                              _c("span", { staticClass: "w-full font-bold" }, [
                                _vm._v(_vm._s(_vm.realSubItemsList.name)),
                              ]),
                            ]),
                            _c("div", { staticClass: "pb-1 pl-2" }, [
                              _c(
                                "div",
                                { staticClass: "flex flex-col" },
                                _vm._l(
                                  _vm.realSubItemsList.items,
                                  function (so, soIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: `keySubItem-${soIndex}`,
                                        staticClass:
                                          "flex flex-row py-1 radio-item",
                                        class: { disabled: _vm.disabled },
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: `subItems-${soIndex}`,
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.lazy",
                                                  value: _vm.selectSubItem,
                                                  expression: "selectSubItem",
                                                  modifiers: { lazy: true },
                                                },
                                              ],
                                              attrs: {
                                                id: `subItems-${soIndex}`,
                                                disabled: _vm.disabled,
                                                type: "radio",
                                                name: so.name,
                                              },
                                              domProps: {
                                                value: soIndex,
                                                checked: _vm._q(
                                                  _vm.selectSubItem,
                                                  soIndex
                                                ),
                                              },
                                              on: {
                                                click: _vm.clickSubItem,
                                                change: function ($event) {
                                                  _vm.selectSubItem = soIndex
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(so.name)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.disabledSubItemsList || _vm.disabled,
                            expression: "disabledSubItemsList || disabled",
                          },
                        ],
                        staticClass: "disabled-section-group",
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "flex items-center p-2 bg-gray-300",
                class: [_vm.horizontalGrow ? "w-full" : ""],
              },
              [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "mr-2 text-xs rounded-full hover:bg-orange-100 custom-p-1",
                    attrs: {
                      disabled: _vm.disabled,
                      color: "orange",
                      "text-color": "white",
                    },
                    on: { click: _vm.apply },
                  },
                  [_c("span", [_vm._v("Apply")])]
                ),
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "text-xs rounded-full hover:bg-gray-100 custom-p-1",
                    attrs: {
                      disabled: _vm.disabled,
                      color: "gray",
                      "text-color": "white",
                    },
                    on: { click: _vm.reset },
                  },
                  [_c("span", [_vm._v("Reset")])]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }